
export default {
  data() {
    return {
      review: null,
      isSending: false,
    }
  },
  async mounted() {
    try {
      const docs = await this.$fire.firestore.collection('reviews').get()
    } catch (error) {
      console.error(error)
    } 
  },
  methods: {
    async send() {
      try {
        this.isSending = true;
        const { review } = this
        const sendReview = await this.$fire.functions.httpsCallable(
          'sendReview'
        )
        await sendReview({ review });
        this.$refs.form.reset();
        this.isSending = false;
        this.$notify({ type: "success", text: 'Review sent successfully. Thank you!'})
      } catch (error) {
        console.error(error)
        this.isSending = false;
        this.$notify({ type: 'error', text: error })
      }
    },
  },
}
