
export default {
  data() {  
    return {
      numImages: 5,
      desktop: [
        require('@/assets/carousel-2025/desktop/1.png'),
        require('@/assets/carousel-2025/desktop/2.png'),
        require('@/assets/carousel-2025/desktop/3.png'),
        require('@/assets/carousel-2025/desktop/4.png'),
        require('@/assets/carousel-2025/desktop/5.png'),
        require('@/assets/carousel-2025/desktop/6.png'),
        require('@/assets/carousel-2025/desktop/7.png'),
      ],
      mobile: [
        require('@/assets/carousel-2025/mobile/1.png'),
        require('@/assets/carousel-2025/mobile/2.png'),
        require('@/assets/carousel-2025/mobile/3.png'),
        require('@/assets/carousel-2025/mobile/4.png'),
        require('@/assets/carousel-2025/mobile/5.png'),
        require('@/assets/carousel-2025/mobile/6.png'),
        require('@/assets/carousel-2025/mobile/7.png'),
      ],
    }
  },
}
