
export default {
  props:{
    products: Array,
  },
  methods:{
    itemCost(cost, discount){
      if(isNaN(+cost)) return 0.00
      if(isNaN((+cost)-(+discount))) return (+cost).toFixed(2);
      return ((+cost)-(+discount)).toFixed(2);
    },
    itemLink(item){
      const {linkCategory= undefined,linkName= undefined, linkSubCategory = undefined} = item;
      if(!linkSubCategory)
      return `${linkCategory}/${linkName}/`
      return `${linkCategory}/${linkSubCategory}/${linkName}/`
    }
  }

}
