
import { mapActions } from "vuex";

export default {

  head() {
    return {
      title: 'Pen The Journey • Hand-crafted Stationery and Planners',
      link: [
        {
          rel: 'canonical',
          href: 'https://penthejourney.com'
        }
      ],
    }
  },
  data() {
    return {
      products: [],
    }
  },
  methods: {
    ...mapActions("product", ["getAllFeaturedProducts"])
  },
  async fetch() {
    const payload = this.$nuxt.context.payload

    if (payload) this.products = { ...payload }


    try {
      const p = await this.getAllFeaturedProducts()
      this.products = [...p];
    } catch (error) {
      console.error(error)
    }
  },
  fetchOnServer: process.env.NODE_ENV !== 'development',
}
