
export default {
  data() {
    return {
      reasons: [
        { image: require('@/assets/whatmakesptjunique/new/5-min.png') },
        { image: require('@/assets/whatmakesptjunique/new/6-min.png') },
        { image: require('@/assets/whatmakesptjunique/new/7-min.png') },
        { image: require('@/assets/whatmakesptjunique/new/8-min.png') },

      ]
    }
  },
}
